import { FIT_ANALYTICS_RECOMMENDATIONS } from '../../utilities/constants/localStorageKeys';
import { storageAvailable } from '../../utilities/helpers';

export const storeFitAnalyticsRecommendation = (
  productSfc: string,
  isWidgetRecommendation: boolean
) => {
  if (!storageAvailable('localStorage')) {
    return false;
  }

  try {
    const fitAnalyticsRecommendedSize = document
      .querySelector('.fitanalytics .fitanalytics__button-text b')
      ?.textContent?.replace('-', '');
    const previousValue = JSON.parse(localStorage.getItem(FIT_ANALYTICS_RECOMMENDATIONS) || '{}');
    const sizeRecommendations = previousValue?.sizeRecommendations || [];
    const immediateRecommendations = previousValue?.immediateRecommendations || [];

    const isAlreadyInSizeRecommendations = sizeRecommendations.find(
      (item: string) => item === productSfc
    );
    const isAlreadyInImmediateRecommendations = immediateRecommendations.find(
      (item: string) => item === productSfc
    );

    if (isAlreadyInSizeRecommendations) {
      return true;
    }

    if (isWidgetRecommendation) {
      sizeRecommendations.push(productSfc);
    } else {
      if (isAlreadyInImmediateRecommendations) {
        return true;
      }

      if (
        !fitAnalyticsRecommendedSize ||
        productSfc.split('-').pop() !== fitAnalyticsRecommendedSize
      ) {
        return false;
      }

      immediateRecommendations.push(productSfc);
    }

    localStorage.setItem(
      FIT_ANALYTICS_RECOMMENDATIONS,
      JSON.stringify({
        sizeRecommendations,
        immediateRecommendations,
      })
    );

    return true;
  } catch (e) {
    return false;
  }
};
